<template>
    <Navbar :user="user" />
    <div class="container" v-if="!isLoading">
      <div class="section-title">
        <span class="sub-title">Step 2 of 3</span>
        <h2>Add Lesson</h2>
      </div>
      <div class="contact-form">
        <form @submit.prevent="createLesson" id="contactForm" novalidate="true">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name">Name</label>
                <input type="text" v-model="form.name" class="form-control" required=""/>
                <div v-if="errors.name" class="text-small text-danger text-start">{{ errors.name[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name" class="text-start">New or Existing Chapter</label>
                  <select v-model="form.lesson_type" id="" class="form-control">
                      <option value="">Choose New Or Existing</option>
                      <option value="1">New Chapter</option>
                      <option value="2">Existing Chapter</option>
                  </select>
                <div v-if="errors.lesson_type" class="text-small text-danger text-start">{{ errors.lesson_type[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="form.lesson_type == 1">
              <div class="form-group text-start">
                  <label for="name" class="text-start">New Chapter Name</label>
                  <input type="text" v-model="form.chapter" id="name" class="form-control" required=""/>
                <div v-if="errors.chapter" class="text-small text-danger text-start">{{ errors.chapter[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="form.lesson_type == 2">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Chapter</label>
                  <select v-model="form.course_content_id" id="" class="form-control">
                      <option value="">Choose Chapter</option>
                      <option :value="chapter.id" v-for="chapter in chapters" :key="chapter.id">{{ chapter.title }}</option>
                  </select>
                <div v-if="errors.course_content_id" class="text-small text-danger text-start">{{ errors.course_content_id[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Duration(seconds)</label>
                  <input type="number" v-model="form.duration" id="name" class="form-control" min="60"/>
                <div v-if="errors.duration" class="text-small text-danger text-start">{{ errors.duration[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                <label for="name" class="text-start">Course Content</label>
                <ckeditor :editor="editor" v-model="form.content"></ckeditor>
                <div v-if="errors.content" class="text-small text-danger text-start">{{ errors.content[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Save Changes</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
     <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  components: { Navbar,Loading, ckeditor: CKEditor.component },
    data(){
      return{
        editor: ClassicEditor,
        alerts: false,
        submitting: false,
        values: [],
        passwordFieldType: "password",
        passwordIcon: true,
        token: localStorage.getItem('user_data'),
        form:{
          name: '',  lesson_type: '', chapter: '',  course_content_id: '', description: '', duration:'',
          content: '', sort_order: 1, course_id:  this.$route.params.id
        },
        user: {},
        errors: {},
        isLoading: true,
        chapters: {},
        lesson: {}
          
      }
  },
   methods:{
    createLesson(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/lesson/store',this.form).then(response =>{
        this.lesson = response.data
        this.$router.push({ name: 'CourseDashboard', params: {id: id}})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getChapters(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_for_select/' + id).then(response => {
        this.chapters = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Course - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getChapters() 
  }

}
</script>

<style>

</style>